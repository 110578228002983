import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.trim.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "constainer"
  }, [_c("div", {
    staticClass: "constainer-header"
  }, [_c("a-form-model", _vm._b({
    ref: "ruleForm",
    attrs: {
      model: _vm.ruleForm,
      rules: _vm.rules,
      form: _vm.form
    }
  }, "a-form-model", _vm.layout, false), [_c("a-form-model-item", {
    attrs: {
      prop: "brandid",
      label: "品牌名称"
    }
  }, [_c("a-select", {
    attrs: {
      size: "large",
      placeholder: "请选择"
    },
    model: {
      value: _vm.ruleForm.brandid,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "brandid", $$v);
      },
      expression: "ruleForm.brandid"
    }
  }, _vm._l(_vm.brandList, function (item) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c("a-form-model-item", {
    attrs: {
      prop: "categoryid",
      label: "品类名称"
    }
  }, [_c("a-cascader", {
    attrs: {
      size: "large",
      options: _vm.category,
      placeholder: "请选择"
    },
    model: {
      value: _vm.ruleForm.categoryid,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "categoryid", $$v);
      },
      expression: "ruleForm.categoryid"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "普通鉴定/币",
      prop: "checkmoney"
    }
  }, [_c("a-input-number", {
    attrs: {
      precision: _vm.precision,
      autoComplete: "off",
      size: "large",
      placeholder: "请输入",
      maxlength: "50",
      min: 0.01
    },
    model: {
      value: _vm.ruleForm.checkmoney,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "checkmoney", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "ruleForm.checkmoney"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "快速鉴定/币",
      prop: "fastcheckmoney"
    }
  }, [_c("a-input-number", {
    attrs: {
      precision: _vm.precision,
      autoComplete: "off",
      size: "large",
      placeholder: "请输入",
      maxlength: "50",
      min: 0.01
    },
    model: {
      value: _vm.ruleForm.fastcheckmoney,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "fastcheckmoney", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "ruleForm.fastcheckmoney"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "AI鉴定/币",
      prop: "aicheckmoney"
    }
  }, [_c("a-input-number", {
    attrs: {
      precision: _vm.precision,
      autoComplete: "off",
      size: "large",
      placeholder: "请输入",
      maxlength: "50",
      min: 0.01
    },
    model: {
      value: _vm.ruleForm.aicheckmoney,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "aicheckmoney", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "ruleForm.aicheckmoney"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "普通鉴定结算/RMB",
      prop: "settlement"
    }
  }, [_c("a-input-number", {
    attrs: {
      precision: _vm.precision,
      autoComplete: "off",
      size: "large",
      placeholder: "请输入",
      maxlength: "50",
      min: 0.0
    },
    model: {
      value: _vm.ruleForm.settlement,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "settlement", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "ruleForm.settlement"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "快速鉴定结算/RMB",
      prop: "fastsettlement"
    }
  }, [_c("a-input-number", {
    attrs: {
      precision: _vm.precision,
      autoComplete: "off",
      size: "large",
      placeholder: "请输入",
      maxlength: "50",
      min: 0.0
    },
    model: {
      value: _vm.ruleForm.fastsettlement,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "fastsettlement", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "ruleForm.fastsettlement"
    }
  })], 1), _c("a-form-model-item", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("a-button", {
    staticClass: "btn btn_l",
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.cancle
    }
  }, [_vm._v(_vm._s("取消"))]), _c("a-button", {
    staticClass: "btn btn_r",
    attrs: {
      size: "large",
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(_vm._s(_vm.isEdit ? "保存" : "创建"))])], 1)], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
var searchKeys = [{
  key: 'brandid',
  label: '品牌',
  placeholder: "请选择",
  select: true
}, {
  key: 'categoryid',
  label: '类目',
  placeholder: "请选择",
  cascader: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };